export default {
    name: "gallery",
    created () {
    },
    mounted () {
        this.$nextTick(function () {
            $.ajax({
                type: "POST",
                dataType: 'json',
                url: this.$js.webservice + "GetDeployedGallery",
                data: { token: "webkey" },
                success: (response) => {
                    //console.log(response);
                    if (response.status == 'SUCCESS') {
                        console.log(response.text)
                        this.liSelectClick(response.text);
                    }
                },
                error: function (XMLHttpRequest) {
                }
            });
        });
    },
    data () {
        return {
            img: require("../../../public/image/gallery_video.png")
        };
    },
    methods: {
        liSelectClick (data) {
            var then = this
            $(".galley_select li:first").addClass("gallery_lion").siblings().removeClass("gallery_lion");
            if (data['Seminars']) {
                then.imageCarousel(data['Seminars']);
            }
            $(".galley_select li").click(function () {
                then.$js.SetAnchor($('.galley_content').offset().top)
                $(this).addClass("gallery_lion").siblings().removeClass("gallery_lion");
                if (data[$(this).html()]) {
                    then.imageCarousel(data[$(this).html()]);
                } else {
                    then.imageCarousel(0)
                }
            });
        },
        //图片轮播
        imageCarousel (imgList) {
            var listLen = imgList.length;
            var i = 0;
            var com = 0
            var num = 140
            //大的轮播图片
            var divhtml = '';
            let _this = this

            $.each(imgList, function (index, datas) {
                if (datas.image == undefined || datas.image == "") {
                    console.log(datas.video_path)//<source src="movie.ogg" type="video/ogg">
                    if (window.navigator.msSaveOrOpenBlob) {
                        type = 'video/mp3';
                        datas.video_path = datas.video_path.split(".")[0] + ".flash";
                    }
                    divhtml += "<div class='carousel-item'>" + "<video controls='controls' class='galley_banner_img'><source src='" + datas.video_path + "' type='video/mp4'></source></video>" + "</div>";
                } else {
                    divhtml += "<div class='carousel-item'>" + "<img src='" + datas.image + "' class='galley_banner_img' alt=''/>" + "</div>";
                }
            });
            $('.carousel-inner').html(divhtml);
            //小的轮播图片
            var lihtml = '';

            $.each(imgList, function (index, datas) {
                if (datas.image == undefined || datas.image == "") {
                    lihtml += "<li class='cur-pointer'>" + "<img src='" + _this.img + "' style='padding:10px;object-fit:contain;'/>" + "</li>";
                } else {
                    lihtml += "<li class='cur-pointer'>" + "<img src='" + datas.image + "' />" + "</li>";
                }
            });
            if (imgList.length > 4) {
                $('.galley_banner_li_box').find('.glyphicon').show()
            } else {
                $('.galley_banner_li_box').find('.glyphicon').hide()
            }
            $('.img-index').html(lihtml);
            // 一开始轮播时赋值
            $(".carousel-inner div:first").addClass("active");
            $(".img-index li:first").addClass("galley_on");

            //点击图片组切换上面的图片
            $(".img-index li").each(function (index, element) {
                $(element).click(function () {
                    com = i
                    i = index;
                    $(this).addClass("galley_on").siblings().removeClass("galley_on");
                    $('.carousel-inner div').eq(index).addClass('active').siblings().removeClass('active');
                    if (imgList.length > 4) {
                        if (i === 0) {
                            $('.img-index').css({
                                transform: 'translateX(' + num * i + 'px)'
                            })
                        } else {
                            if (i > 2) {
                                if (index > com) {
                                    $('.img-index').css({
                                        transform: 'translateX(' + -num * (i - 2) + 'px)'
                                    })
                                } else {
                                    $('.img-index').css({
                                        transform: 'translateX(' + num * (i - 2) + 'px)'
                                    })
                                }

                            }
                        }
                    }

                })
            })
            //走马灯轮播是图片组对应索引图片
            $('#carouselExampleControls').on('slide.bs.carousel', function () {
                if (i < listLen - 1) { i++; } else { i = 0; };
                $(".img-index li").eq(i).addClass("galley_on").siblings().removeClass("galley_on");
                if (imgList.length > 4) {
                    if (i === 0) {
                        $('.img-index').css({
                            transform: 'translateX(' + -num * i + 'px)'
                        })
                    } else {
                        if (i > 2) {
                            $('.img-index').css({
                                transform: 'translateX(' + -num * (i - 2) + 'px)'
                            })
                        }
                    }
                }
                // 小的轮播图片的左右箭头功能
                $('.galley_banner_li_box').find('.glyphicon-chevron-left').click(function () {
                    i = i - 1
                    $(".img-index li").eq(i).addClass("galley_on").siblings().removeClass("galley_on");
                    $('.carousel-inner div').eq(i).addClass('active').siblings().removeClass('active');
                    if (i < 0) {
                        i = 0
                    }
                    if (i === 0) {
                        $('.img-index').css({
                            transform: 'translateX(' + num * i + 'px)'
                        })
                    } else {
                        if (i > 2) {
                            $('.img-index').css({
                                transform: 'translateX(' + num * (i - 2) + 'px)'
                            })
                        }
                    }
                })
                $('.galley_banner_li_box').find('.glyphicon-chevron-right').click(function () {
                    i = i + 1
                    $(".img-index li").eq(i).addClass("galley_on").siblings().removeClass("galley_on");
                    $('.carousel-inner div').eq(i).addClass('active').siblings().removeClass('active');
                    if (i > imgList.length - 1) {
                        i = 0
                    }
                    if (i === 0) {
                        $('.img-index').css({
                            transform: 'translateX(' + -num * i + 'px)'
                        })
                    } else {
                        if (i > 2) {
                            $('.img-index').css({
                                transform: 'translateX(' + -num * (i - 2) + 'px)'
                            })
                        }
                    }
                })
            })
            this.arrowsReplace()
        },

        // 箭头替换
        arrowsReplace () {
            var $preva = $('.carousel-control-prev')
            var $nexta = $('.carousel-control-next')
        }
    }
}